export default function (pageName, pageParams, pageClasses, application, backUrl, introText = '', outtroText = '') {
	this.wrap('page/wrapper', pageName, pageParams, pageClasses);
	return `

		${this.cond(introText.length, () => `
			<div ${this.html.classAttr('block__text', 'block__text--previewIntro', 'ugc')}>
				${introText}
			</div>
		`)}

		<div ${this.html.classAttr('block__pageTitle')}>
			<h2>${this.escape(application.title)}</h2>
			<div ${this.html.classAttr('pageActions')}>
			<button ${this.html.classAttr('iconButton', 'pageActions__iconBtn')} ${this.html.dataAttr({component: 'PrintBtn'})} type="button">
				${this.html.svgIcon('print')}
			</button>
		</div>
		</div>

		<div ${this.html.classAttr('applicationView')}>
			<ul ${this.html.classAttr('applicationView__sections')}>
				${this.loop(application.sections, (section) => `
					<li ${this.html.classAttr('applicationView__section')}>
						<h3 ${this.html.classAttr('applicationView__sectionTitle')}>${this.escape(section.title)}</h3>
						<ul ${this.html.classAttr('applicationView__fields')}>
							${this.loop(section.fields, (field) => `
								<li ${this.html.classAttr('applicationView__field', 'applicationView__field--' + field.type)}>
									${this.cond(field.type === 'image', () => {
										const subfields = field.value;
										return `
											<div ${this.html.classAttr('applicationView__imageWrapper')}>
												${this.loop(subfields, (subfield) => this.cond(subfield.type === 'imagefile' && 'path' in subfield.value, () => `
													<div ${this.html.classAttr('applicationView__fieldLabel')}>
														File:
													</div>
													<div ${this.html.classAttr('applicationView__fieldValue')}>
														${this.escape(subfield.value.ext.toUpperCase())} ${this.escape(this.string.fileSize(subfield.value.size))} ${this.escape(subfield.value.width)}x${this.escape(subfield.value.height)}
													</div>
													<img ${this.html.classAttr('applicationView__image')} src="${this.thumb.getUrl(subfield.value.path, 768)}" />
												`))}
											</div>
											<ul ${this.html.classAttr('applicationView__imageFields')}>
												${this.loop(subfields, (subfield) => this.cond(subfield.type !== 'imagefile', () => `
												<li ${this.html.classAttr('applicationView__imagefield', 'applicationView__imagefield--' + field.type)}>
													<div ${this.html.classAttr('applicationView__imagefieldLabel')}>
														${this.escape(subfield.label)}
													</div>
													<div ${this.html.classAttr('applicationView__imagefieldValue')}>
														${this.render('application/fieldValue', subfield)}
													</div>
												</li>
												`))}
											</ul>
										`;
									}, () => `
										<div ${this.html.classAttr('applicationView__fieldLabel')}>
											${this.escape(field.label)}
										</div>
										<div ${this.html.classAttr('applicationView__fieldValue')}>
											${this.render('application/fieldValue', field)}
										</div>
									`)}
								</li>
							`)}
						</ul>
					</li>
				`)}
			</ul>

			${this.cond(parseInt(application.submitted, 10), () => `
				<div ${this.html.classAttr('applicationView__footer')}>
					${this.cond(outtroText.length, () => `
						<div ${this.html.classAttr('applicationView__footerText', 'ugc')}>
							${outtroText}
						</div>
					`)}
					${this.cond(introText.length, () => `
						<div ${this.html.classAttr('applicationView__footerDate')}>
							${this.text.get('application/previewDate')}
						</div>
						<div ${this.html.classAttr('applicationView__footerSignature')}>
							${this.text.get('application/previewSignature')}
						</div>
					`)}
				</div>
			`)}
		</div>

		<div ${this.html.classAttr('pageActions')}>
			<button ${this.html.classAttr('iconButton', 'pageActions__iconBtn')} ${this.html.dataAttr({component: 'PrintBtn'})} type="button">
				${this.html.svgIcon('print')}
			</button>
		</div>

	`;
}
